import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="Footer">
                <div className="ui stackable four column centered grid">
                    <div className="column">
                        <div className="ui grey medium header">Location</div>
                        <div className="sub red header">Sub Header</div>
                    </div>
                    <div className="column">b</div>
                    <div className="column">c</div>
                </div>
            </div>
        );
    }
}

export default Footer;



