import React, { Component } from 'react';
import GitHubButton from 'react-github-btn';
import './profile.css';
  
class Profilename extends Component {
  render() {
    return (

    <div className="Profile">
          <header className="Profile-header" id="home">
          <div>
            <img alt='Jon Russell' id="bioPic"  className="ui small circular image" src="https://pbs.twimg.com/profile_images/1230224901660909569/nbcUAFci_400x400.jpg"/>
            <h1 className="myName">Jon Russell</h1>
            <h4 className="myProfession" style={{marginTop:-20}}>Junior Software Engineer</h4>
              <h4 className="myProfession ui header" style={{marginTop:-15, color:'white'}}>(Liverpool, UK)</h4>
            <div className="ui one column centered grid">
              <div className="little-bio">
                  <p className="bio-text">Graduated with a first class in Software Engineering in 2018. Working as Junior Developer.
                  Currently working on an open source project in my spare time
                      <div data-tooltip="Jonopoly@GitHub" data-position="bottom center" style={{marginTop: 10}}>
                          <GitHubButton href="https://github.com/Jonopoly/AnonyPy" data-show-count="true" aria-label="Star Jonopoly/AnonyPy on GitHub">AnonyPy</GitHubButton>
                      </div>
                      </p>
              </div>
            </div>
            </div>
          </header>
        </div>
      );
    }
  }
  
  export default Profilename;
    
    