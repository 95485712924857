import React, { Component } from 'react';
import './App.css';
import Profilename from './Components/profile/Profilename';
import Skills from './Components/aboutme/Skills';
import Projects from './Components/projects/Projects';
import Menu from './Components/menu/Menu';
import CompactMenu from './Components/menu/CompactMenu'
import Contact from './Components/contact/Contact'
import Footer from './Components/footer/Footer'


class App extends Component {
  constructor(props){
    super(props);
    this.state ={width: 0, height: 0};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    console.clear();
    console.info("You're changing your screen size:",window.innerWidth);
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  menuPicker(){
    if( this.state.width < 600){
      return (<CompactMenu />)
    }else{
      return (<Menu />)
    }
  }

  render() {
    return (

    <div style={{backgroundColor: '#282c34'}}> 
     {this.menuPicker()}
     <Profilename/>
     <Skills />
     {/*<Projects width={this.state.width}/>*/}
     <Contact />
     {/*<Footer />*/}
     </div>
    );
  }
}

export default App;
