import React, { Component } from 'react';
import SkillsTable from './SkillsTable';
import './AboutMe.css';
import TableForSkills from "./TableForSkills";

class Skills extends Component {
  render() {
    return (
     
 <div className="App">
        <header className="App-headerAbo" id="skills"  >
        <div className="ui card" id="Jon" style={{width:'100vh'}}>
          <div className="content">
         
            <div className="header">Skills</div>
            <div className="meta">Updated February 2nd 2020</div>
            <div class="ui divider" />
            <div className="description">
            {/*<SkillsTable />*/}
            <TableForSkills />
            </div>          
          </div>
        </div>
        </header>
      </div>
    );
  }
}

export default Skills;
 
 
 