import React, { Component } from 'react';
import './AboutMe.css'

class SkillsTable extends Component {
    render() {
        return (
            <table class="ui definition selectable celled table">
            <tbody>
              <tr>
                <td>Programming Languages</td>
                <td>Highly competent in C#, Java, Python, JavaScript, CoffeeScript and Ruby.</td>
              </tr>
              <tr>
                <td>Web Design and Development</td>
                <td>Excellent skills in React, Redux, HTML, CSS and Bootstrap.</td>
              </tr>
              <tr>
                <td>Database Skills</td>
                <td>Advanced MySQL, Excel, SQLite and PHP skills.</td>
              </tr>
              <tr>
                <td>Operating Systems</td>
                <td>Windows (all versions from Windows XP), Linux (Ubuntu, KDE and Mate)</td>
              </tr>
              <tr>
                <td>Software Development</td>
                <td>Familiar with agile development, waterfall, rapid application development and spiral life cycle models. </td>
              </tr>
              <tr>
                <td>Other</td>
                <td>Highly competent in Android Development, Shell, Bash, Unit Testing, Adobe Photoshop and Microsoft Office, GitHub and Amazon Web Services.</td>
              </tr>
            </tbody>
          </table> 
        );
    }
}

export default SkillsTable;