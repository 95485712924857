import React, { Component } from 'react';
import './AboutMe.css'

class TableForSkills extends Component {
    render() {
        return (
            <div className="ui two column doubling stackable grid container">
                <div className="column">
                    <p className='skill-text'><i className="terminal icon"/> Java <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> C# <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Python <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Javascript <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Ruby <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> MySQL <i>(2 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> SqlLite <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> HTML <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> CSS <i>(1 year)</i></p>
                </div>
                <div className="column">
                    <p className='skill-text'><i className="terminal icon"/> React and Redux <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Apache Maven <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Agile and Scrum <i>(2 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Bash <i>(2 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Shell <i>(2 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Git <i>(2 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Redux <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Unit Testing <i>(1 year)</i></p>
                    <p className='skill-text'><i className="terminal icon"/> Windows and Linux</p>
                </div>
            </div>
        );
    }

}
export default TableForSkills;