import React, { Component } from 'react';
import tt from './url.txt';
import './Menu.css'
const isReachable = require('is-reachable');
let pi_url = "http://90.240.85.116:3000/"





class Menu extends Component {
    constructor(props) {
        super(props);
        this.state ={piOnline: false}
    
    
        // This binding is necessary to make `this` work in the callback
        this.goToSection = this.goToSection.bind(this);
        this.PiVersion = this.PiVersion.bind(this);
        this.setPi = this.setPi.bind(this);
	fetch(tt)
       .then((r) => r.text())
       .then(text  => {
         text = text.replace("/\r?\n|\r/g", "");
         pi_url = text;
    });


      }

      handleClick(){
        // alert("Hello")
        window.open('pi.jonrussell.co.uk')
      }
      componentDidMount(){
        this.interval = setInterval(async () => {
          //  When Pi is set up it will show. Currently not hosting website on Pi ATM.
          const piOnline = await isReachable(pi_url)
          this.setState({
            piOnline,
          });
        }, 1000);
      }
      componentWillUnmount() {
        clearInterval(this.interval);
      }

      setPi(){
        if(this.state.piOnline){
          return ( 
          <a href='pi.html/' onClick={this.handleClick } style={{color:'darkgreen'}} className="item navmenu"> Pi Online.</a>
          )
        }else{
          return (<div className="item navmenu" style={{color:'darkred'}} > Pi Offline.</div>)
        }
        
      }
      PiVersion(){
       (async () => {
          this.setState({piOnline: (await isReachable(pi_url))}) 
      })();


      }


    goToSection (event, poo){
      event.preventDefault();
     document.getElementById(poo).scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
    
  render() {
    return (
     <div className="ui inverted menu" style={{background: '#41393E', position:"fixed", width:"100%", zIndex:1}}>
     <div className="left Menu">
         <a className="item navmenu" href='/'onClick={(e) => {this.goToSection(e, 'home')}} >Jon Russell</a>
     </div>
     {/*{this.setPi()}*/}
     <div className="right menu">
          <a className="item navmenu" href='#skills' onClick={(e) => {this.goToSection(e, 'skills')}}>Skills</a>
          <a className="item navmenu" href='#Contact' onClick={(e) => {this.goToSection(e, 'Contact')}}>Contact</a>
        </div> 
      </div>
    );
  }
}

export default Menu;

 


