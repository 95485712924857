import React, { Component } from 'react';
import './Projects.css';
// import * as jsonData from './Projects.json'
import ProjectCard from './ProjectCard'
import DATA from './ListOfProjects'

class Projects extends Component {
  constructor(props) {
    super(props);
    this.section1 = React.createRef();



    // This binding is necessary to make `this` work in the callback
  }

  render() {


    return (
      <div className="App" ref={this.section1} id="projects">
        <header className="App-headerPro" style={{paddingTop:40, paddingBottom:30}}>
          <h1>Projects</h1>
          <div className="ui fluid container">
            <div className="ui stackable grid">
              {Object.values(DATA.Projects).map(item => 
                { return <div key={item.id} className="eight wide column">
                            <ProjectCard 
                              name={item.name}
                              meta={item.meta}
                              desc={item.desc}
                              year={item.year}
                              img={item.img}
                              width={this.props.width}
                            />
                          </div>
                })
              }
              </div> 
            </div>     

        </header>
      </div>
    );
  }
}

export default Projects;

 
 
 