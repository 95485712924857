import React, { Component } from 'react';

class CompactMenu extends Component {
    constructor(props) {
        super(props);
        this.goToSection = this.goToSection.bind(this);
      }



    goToSection (event, cats){
      event.preventDefault();
     document.getElementById(cats).scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
    
  render() {
    return (
     <div className="ui medium inverted menu" style={{background: '#41393E', position:"fixed", width:"100%", height:"1%", zIndex:1}}>
        <div className="ui medium four item menu">
            <a className="item navmenu" href='/'onClick={(e) => {this.goToSection(e, 'home')}} >Jon Russell</a>
            <a className="item navmenu" href='#skills' onClick={(e) => {this.goToSection(e, 'skills')}}>Skills</a>
            <a className="item navmenu" href='#Contact' onClick={(e) => {this.goToSection(e, 'Contact')}}>Contact</a>
        </div> 
      </div>
    );
  }
}

export default CompactMenu;