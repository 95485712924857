import GitHub from './img/gitChecker.png'
import discordBot from './img/discordBot.png'
var DATA = {"Projects":[

    {
        "id": "1",
        "name": "GitHub Checker",
        "meta":"C# and Shell",
        "desc": "This application was made to check multiple repos I was working on with students and colleagues whilst on placement. It would constly check for updates.",
        "img": GitHub,
        "year": "2017"
    },
    {
        "id": "2",
        "name":"Discord Bot",
        "meta":"Ruby",
        "desc": "Small bot for a discord server which can assign roles and log complaints",
        "img":discordBot,
        "year": "2017"
    },
    {
        "id": "3",
        "name":"Video Convertor CLI",
        "meta":"Shell",
        "desc": "Created on placement as a temporary application to split/crop videos and rotate angles. Using Shell and FFmpeg",
        "img":require('./img/VideoConvert_CLI.png'),
        "year": "2017"
    },
    {
        "id": "4",
        "name":"Multi Repo Fetch",
        "meta":"Shell",
        "desc": "Would auto run on load to check if all repos where upto date. I would use this to ensure there had been no pushes before I started.",
        "img":require('./img/fetchUpdates.png'),
        "year": "2017"
    }, {
        "id": "5",
        "name":"Silent Installer",
        "meta":"Shell",
        "desc": "I crated this on placement as I had to create a one-click installer. This would install several things at once such as Java and Flash all at the press off one click.",
        "img":require('./img/silentInstall.png'),
        "year": "2017"
    }, {
        "id": "6",
        "name":"Video Convertor GUI",
        "meta":"C#",
        "desc": "Using FFMpeg and Windows.squirrel i was alble to create a GUI version of Video Converter CLI. with automatic updates using Squirrel.",
        "img":require('./img/VideoConvertGUI.png'),
        "year": "2017"
    }, {
        "id": "7",
        "name":"Multiple Youtube Video Downloader",
        "meta":"C#",
        "desc": "I had to create an application that could download youtube videos at their highest resolution available.",
        "img":require('./img/YoutubeDownloader.png'),
        "year": "2017"
    }


]
}

export default DATA
