import React, { Component } from 'react';
import './Contact.css';

class Contact extends Component {
  render() {
    return (
      <div className="App Contact "> 
      <header className="App-contactMe" id="Contact">
        <div class="card">
        <div class="content">
          <p class="header">
          <h1 className='ContactMe ui header ' style={{color:'white'}}>Get in touch</h1>
          </p>
          <div class="meta">
            <span style={{color:'darkgrey'}}>
            <h2 className='EmailAddress ui header' style={{color:'white'}}>hello@jonrussell.co.uk</h2>
            </span>
          </div>
        </div>
        </div>
      </header>
      </div>
    );
  }
}

export default Contact;

 
 
 