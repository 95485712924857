import React from 'react'
//https://www.udemy.com/react-redux/learn/v4/t/lecture/12531338?start=0
//3:49 HERE I AM

class ProjectCard extends React.Component{  

    projectSort(){
       if(this.props.width <= 767){
           return(
            <div className="item">
            <div className="content">
                <p className="header" style={{color:'white'}}>
                    {this.props.name}
                </p>
                <p>
                    <div className="ui medium rounded image" >
                        <img alt="about project"  src={(this.props.img)}/>
                    </div>
                </p>
                <div className="meta" style={{color:'#787878'}}>
                    <span>Written in: {this.props.meta}</span>
                </div>
                <div className="description" style={{color:'white'}}>
                    {this.props.desc}
                </div>
                <div className="extra" style={{color:'grey'}}>
                    Created: {this.props.year} and {this.props.width}
                </div>
            </div>
        </div>
           )
       }else{
           return(
            <div className="item">
            <div className="ui medium rounded image" >
                <img alt="about project"  src={(this.props.img)}/>
            </div>
            <div className="content">
                <p className="header" style={{color:'white'}}>
                    {this.props.name}
                </p>
                <div className="meta" style={{color:'#787878'}}>
                    <span>Written in: {this.props.meta}</span>
                </div>
                <div className="description" style={{color:'white'}}>
                    {this.props.desc}
                </div>
                <div className="extra" style={{color:'grey'}}>
                    Created: {this.props.year} and {this.props.width}
                </div>
            </div>
        </div>
           )
       }
    }

render() {     
        return (
            
            <div className="ui items ">
            {this.projectSort()}

            </div> 
        );
    }
}

export default ProjectCard
